import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  Home,
  About,
  Services,
  Event,
  Contact,
  Member,
  NotFound,
  Blog,
  Avantage,
  EventView,
  BlogView,
  Unsubscribe,
  Formation,
  FormationView,
  FormationSignUp,
  Emploi,
  Stage,
  Realisation
} from "../screens";
import Nav from "../Navigations/Nav";
import Footer from "../components/Footer";
import ToTop from "../components/ToTop";
import ScrollToTop from "react-scroll-to-top";

const index = () => {
  return (
    <Router>
      <Nav />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/a-propos" exact component={About} />
        <Route path="/services" exact component={Services} />
        <Route path="/evenements" exact component={Event} />
        <Route path="/evenements/:id" exact component={EventView} />
        <Route path="/contactez-nous" exact component={Contact} />
        <Route path="/devenir-membre" exact component={Member} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:id" exact component={BlogView} />
        <Route path="/avantages" exact component={Avantage} />
        <Route path="/unsubscribe" exact component={Unsubscribe} />
        <Route path="/formations" exact component={Formation} />
        <Route path="/formations/:id" exact component={FormationView} />
        <Route
          path="/formations/inscription/:id"
          exact
          component={FormationSignUp}
        />
        <Route path="/emploi" component={Emploi} />
        <Route path="/stage" component={Stage} />
        <Route path="/realisations" component={Realisation} />
        <Route path="/:path" component={NotFound} />
      </Switch>
      <ScrollToTop style={{ bottom: 30 }} smooth component={<ToTop />} />
      <Footer />
    </Router>
  );
};

export default index;
