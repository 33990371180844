import React from "react";
import Fade from "react-reveal/Fade";

const Header = ({ shadow, title, description, auteur, slogan }) => {
  return (
    <header>
      <h1 className={shadow ? "threeD" : null}>{title}</h1>
      <h2>{description}</h2>
      {auteur ? <span className="auteur">- {auteur}</span> : null}
      {slogan ? <h3>{slogan}</h3> : null}
    </header>
  );
};

export default Header;
