/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { dateParser } from "../components/Utils";
import Nothing from "../components/Nothing";

const BlogView = ({ match }) => {
  const location = useLocation();
  const [blogInfo, setBlogInfo] = useState({});
  const [exist, setExist] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/blog/${match.params.id}`)
      .then((res) => {
        setBlogInfo(res.data);
        document.title = blogInfo.title;
        document.querySelector(".description").innerHTML = blogInfo.description;
      })
      .catch((err) => setExist(false));
  }, [blogInfo.description, blogInfo.title, match.params.id]);

  return (
    <div className="page-content">
      {exist ? (
        <div className="view-container">
          <div className="card">
            <div className="img">
              <img
                src={`${process.env.REACT_APP_SERVER}/${blogInfo.image}`}
                alt="Event_image"
              />
            </div>
            <div className="info">
              <h1>{blogInfo.title}</h1>
              <p className="date">
                Publié le: {dateParser(blogInfo.createdAt)}
              </p>

              <p className="description" />

              <input
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_CLIENT}${location.pathname}`
                  );
                  setTimeout(() => {
                    document.querySelector(".to-copy").value =
                      "Copier le lien et partager";
                  }, 3000);

                  document.querySelector(".to-copy").value = "Copié";
                }}
                className="to-copy"
                defaultValue="Copier le lien et partager"
              />
            </div>
          </div>
        </div>
      ) : (
        <Nothing
          name={"articles"}
          locate={"blog"}
          texte={"Cet article n'exite pas"}
        />
      )}
    </div>
  );
};

export default BlogView;
