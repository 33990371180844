import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import axios from "axios";
import SendBox from "../components/SendBox";

const Emploi = () => {
  const [send, setSend] = useState(false);

  useEffect(() => {
    document.title = "Postuler pour un emploi";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: ""
  });
  const [file, setFile] = useState();

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };
  function handleChangeFile(e) {
    setFile(e.target.files[0]);
    document.querySelector(".custum-file-select").innerHTML =
      e.target.files[0].name;
  }

  const handleMessage = (e) => {
    e.preventDefault();
    const EmploiData = new FormData();

    EmploiData.append("firstname", data.firstname);
    EmploiData.append("lastname", data.lastname);
    EmploiData.append("phone", data.phone);
    EmploiData.append("email", data.email);
    EmploiData.append("message", data.message);
    EmploiData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_SERVER}/api/message/emploi`, EmploiData)
      .then((res) => {
        if (res.data.errors) {
          document.querySelector(".custum-file-select").innerHTML =
            `${res.data.errors.format}` || `${res.data.errors.maxSize}`;
          document.querySelector(".custum-file-select").style.color = "red";
          setTimeout(() => {
            document.querySelector(".custum-file-select").innerHTML = file.name;
            document.querySelector(".custum-file-select").style.color = "white";
          }, 4000);
        }
      })
      .catch((err) => console.log("message not send"));
    setData({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: ""
    });
    setSend(true);

    setTimeout(() => {
      setSend(false);
    }, 4000);
    setFile();
  };

  return (
    <div className="page-content">
      {" "}
      <Header title={"Postuler pour un emploi."} />
      <section className="contact-section">
        <div className="contact-container">
          <div className="contact-form">
            <h2>Envoyez votre requête</h2>
            <form
              method="post"
              name=""
              className="formBox"
              onSubmit={handleMessage}
            >
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  value={data.firstname}
                  required
                />
                <span>Prénom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  value={data.lastname}
                  required
                />
                <span>Nom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                />
                <span>Email *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  value={data.phone}
                  required
                />
                <span>Numéro de téléphone *</span>
              </div>
              <label htmlFor="file" className="custum-file-select">
                Téléverser votre CV ici *
              </label>

              <div className="inputBox w50 to-clear ">
                <input
                  type="file"
                  name="file"
                  accept=".doc, .docx, .pdf, .pptx, .ppt"
                  onChange={handleChangeFile}
                  required
                  id="file"
                  className="display-none"
                />
              </div>
              <div className="inputBox w100 to-clear">
                <textarea
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                  required
                />
                <span>Un message *</span>
              </div>

              <div className="inputBox w100">
                {!send ? (
                  <input type="submit" name="send" value="Envoyer la requête" />
                ) : (
                  <SendBox
                    text="Requête d'emploi envoyée"
                    onClick={() => setSend(false)}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Emploi;
