import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import EventCard from "../components/EventCard";
import axios from "axios";
import { isEmpty, timestampParser } from "../components/Utils";
import Search from "../components/Search";
import Images from "../constantes/images";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Événements";
    window.scrollTo({ top: 0, behavior: "smooth" });

    axios
      .get(`${process.env.REACT_APP_SERVER}/api/event`)
      .then((res) => {
        setEventData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const updateInput = async (input) => {
    const filtered = eventData.filter((event) => {
      const fullDate = timestampParser(event.date);

      return (
        event.title.toLowerCase().includes(input.toLowerCase()) ||
        fullDate.toLowerCase().includes(input.toLowerCase()) ||
        event.heure.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setFilterData(filtered);
  };

  return (
    <div className="page-content">
      {" "}
      <Header
        title={"Événements."}
        description={
          "Formation, Coaching individuel, club informatique etc, tous les événements sont là."
        }
      />
      <div className="search-container">
        <Search
          input={input}
          setKeyword={updateInput}
          placeholder="un événement... (Nom, Date, Heure)"
        />
      </div>
      {loading ? (
        <div className="loading">
          <img src={Images.load} alt="loading" />
        </div>
      ) : (
        <div className="display-grid">
          {filterData ? (
            filterData.map((data) => {
              return <EventCard EventData={data} key={data._id} />;
            })
          ) : !isEmpty(eventData) ? (
            eventData.map((data) => {
              return <EventCard EventData={data} key={data._id} />;
            })
          ) : (
            <p className="nothing">aucun événement</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Events;
