import React, { useState } from "react";
import Images from "../constantes/images";

const Darkmode = () => {
  const [ isDark, setIsDark ] = useState(false);

  function handleDark() {
    setIsDark(!isDark);
    let root = document.documentElement;

    if (!isDark) {
      root.style.setProperty("--first-color", "#111111");
      root.style.setProperty("--bg", "#2A2B2E");
      root.style.setProperty("--white", "rgb(222, 225, 236)");
      root.style.setProperty("--black", "#f0f0f0");
      root.style.setProperty("--first-letter", "#f0f0f0");
      root.style.setProperty("--white-card", "#2A2B2E");
    } else {
      root.style.setProperty("--first-color", "#3B5995");
      root.style.setProperty("--bg", "#f0f0f0");
      root.style.setProperty("--white", "#fff");
      root.style.setProperty("--black", "#121212");
      root.style.setProperty("--first-letter", "#3B5995");
      root.style.setProperty("--white-card", "#fff");
    }
  }

  return (
    <div
      className={isDark ? "dark-container white" : "dark-container"}
      onClick={handleDark}
    >
      <img
        src={isDark ? Images.sun : Images.moon}
        alt="dark_swipe"
        className={!isDark ? "white" : null}
      />
    </div>
  );
};

export default Darkmode;
