import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";

const Unsubscribe = () => {
  const [ isOk, setIsOk ] = useState(false);
  const [ email, setEmail ] = useState("");

  useEffect(() => {
    document.title = "Se désinscrire aux newsletters";
  }, []);

  function handleUnsubscrive(e) {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_SERVER}/api/email/${email}`)
      .then((res) => {
        if (res.data.includes("n'est pas abonné au newsletter")) {
          document.querySelector(".unsubscribe").innerHTML = res.data;
          setTimeout(() => {
            document.querySelector(".unsubscribe").innerHTML = "";
          }, 3000);
        } else {
          document.querySelector(".unsubscribe").innerHTML = "";
          setIsOk(true);
          setEmail("");
          setTimeout(() => {
            window.location = "/";
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="page-content">
      <Header title={"Oups..."} description={"Qu'est ce qui na pas marché ?"} />
      <div className="unsubscrive-container">
        <h1>Êtes vous sûrs de vous désabonner ?</h1>
        <form action="" onSubmit={handleUnsubscrive}>
          <label htmlFor="">Entrez votre email pour valider</label>
          <input
            type="email"
            placeholder="info@sc-digital.org"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input type="submit" value={isOk ? "Désabonné" : "Confirmer"} />
          <label className="unsubscribe" />
        </form>
      </div>
    </div>
  );
};

export default Unsubscribe;
