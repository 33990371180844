/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Images from "../constantes/images";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";

const Home = () => {
  const [toShow, setToShow] = useState(3);

  useEffect(() => {
    document.title = "SC Digital - Accueil";
    window.scrollTo({ top: 0, behavior: "smooth" });
    var w;
    if (window.innerWidth !== undefined) {
      w = window.innerWidth;
    } else {
      w = document.documentElement.clientWidth;
    }

    if (w < 770) {
      setToShow(2);
    }
    if (w < 570) {
      setToShow(1);
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    adaptiveHeight: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: toShow,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    )
  };

  return (
    <div className="page-content">
      <Header
        title={"SC DIGITAL, la startup révolutionnaire."}
        description={
          "“Avec les jeunes, tout est possible. Donnez-leur juste la possibilité de s'exprimer. Et le talent apparaîtra sans pression.”"
        }
        auteur={"Sekou KONATE"}
        slogan={"Ensemble réalisons vos projets digitaux."}
      />
      <div className="team-section">
        <div className="left">
          <Fade left>
            <h1>
              Nous sommes là pour vous aider à réussir dans le domaine
              Numérique.
            </h1>
          </Fade>
          <Fade right>
            <p>
              Après de nombreuses expériences personnelles et professionnelles,
              nous avons décidé de fonder SC DIGIT@L afin d'aider les
              particuliers à devenir excellent dans le domaine informatique.
              Grâce à nos formateurs 3.0 vous serez initiés rapidement dans le
              domaine numérique.
            </p>
          </Fade>
          <NavLink to="/a-propos">
            <input
              type="button"
              className="btn-design"
              value="En savoir plus"
            />
          </NavLink>
        </div>
        <Fade right>
          <div className="right">
            <img src={Images.group} alt="team" />
          </div>
        </Fade>
      </div>

      <div className="pedagogie-section">
        <Fade left>
          <img src={Images.pedagogie} alt="pedagogie" />
        </Fade>
        <div className="slide-container">
          <Slider {...settings}>
            <div className="card">
              <h3>L'autonomie</h3>
              <p>Immersion de chaque apprenant dans la réalité.</p>
            </div>
            <div className="card">
              <h3>La permanence</h3>
              <p>
                Impliquer chaque apprenant dans le processus d'apprentissage.
              </p>
            </div>
            <div className="card">
              <h3>La créativité</h3>
              <p>Laisser l'apprenant s'exprimer.</p>
            </div>
          </Slider>
        </div>
      </div>

      <div className="member-aventage">
        <div className="info">
          <Fade left>
            <h3>Les membres bénéficient de plusieurs avantages.</h3>
          </Fade>
          <Fade right>
            <NavLink to="/avantages">
              <input
                type="button"
                className="btn-design"
                value="Découvrir les avantages"
              />
            </NavLink>
          </Fade>
        </div>
        <Fade bottom>
          <img src={Images.team} alt="team" />
        </Fade>
      </div>
      <div className="pack-title" id="pack">
        <h2>Bésoin d'un site web ou application ?</h2>
        <p>
          SC Digital vous offre la possibilité d'en avoir en payant la somme sur
          une période déterminée.
        </p>
      </div>
      <div className="pack-container">
        <Fade top>
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>10.000F/mois (20 mois)</h2>
                <h3>Pack Perso</h3>
                <ul className="list">
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      03 pages web (Accueil, Nos services et contact)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Nom de domaine offert(1an)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Adresses mails professionnels
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Hébergement web offert(1an)
                    </label>
                  </li>

                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Boutique en ligne
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Espace administrateur
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Design personnalisé
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      L’optimisation pour moteur de recherche(SEO)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Support dédié
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Présence de votre entreprise sur Google Maps
                    </label>
                  </li>
                </ul>
                <a
                  className="choose"
                  href={`https://wa.me/${process.env.REACT_APP_WHATSAPP}?text=${process.env.REACT_APP_PERSO_MESSAGE}`}
                  target="_blank"
                >
                  Choisir
                </a>
              </div>
            </div>
          </div>
        </Fade>

        <Fade top>
          <div className="card middle">
            <div className="box">
              <div className="content">
                <h2>20.000F/mois (18 mois)</h2>
                <h3> Pack Starter</h3>
                <ul className="list">
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      04 pages web
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      01 nom de domaine offert(1an)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      02 adresses mails professionnels
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Hébergement web offert(1an)
                    </label>
                  </li>

                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Présence de votre entreprise sur Google Map
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Espace administrateur
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Design personnalisé
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      L’optimisation pour moteur de recherche(SEO)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Support dédié
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <span className="cross"> &#9587;</span>
                      Présence de votre entreprise sur Google Maps
                    </label>
                  </li>
                </ul>
                <a
                  className="choose"
                  href={`https://wa.me/${process.env.REACT_APP_WHATSAPP}?text=${process.env.REACT_APP_STARTER_MESSAGE}`}
                  target="_blank"
                >
                  Choisir
                </a>
              </div>
            </div>
          </div>
        </Fade>

        <Fade top>
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>35.000F/mois (15 mois)</h2>
                <h3>Pack Performance</h3>
                <ul className="list">
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      10 pages web
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      01 nom de domaine offert(1an)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      05 adresses mails professionnels
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Hébergement web offert(1an)
                    </label>
                  </li>

                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Boutique en ligne
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      01 espace administrateur
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Design personnalisé
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      L’optimisation pour moteur de recherche(SEO)
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Support dédié
                    </label>
                  </li>
                  <li className="list__item">
                    <label className="label--checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked
                        readOnly
                      />
                      Présence de votre entreprise sur Google Maps
                    </label>
                  </li>
                </ul>
                <a
                  className="choose"
                  href={`https://wa.me/${process.env.REACT_APP_WHATSAPP}?text=${process.env.REACT_APP_PERFORMANCE_MESSAGE}`}
                  target="_blank"
                >
                  Choisir
                </a>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Home;
