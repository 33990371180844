/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Nothing from "../components/Nothing";
import { timestampParser } from "../components/Utils";

const EventView = ({ match }) => {
  const location = useLocation();
  const [eventInfo, setEventInfo] = useState({});
  const [exist, setExist] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/event/${match.params.id}`)
      .then((res) => {
        setEventInfo(res.data);
        document.title = eventInfo.title;
        document.querySelector(".description").innerHTML =
          eventInfo.description;
      })
      .catch((err) => setExist(false));
  }, [eventInfo.description, eventInfo.title, match.params.id]);

  return (
    <div className="page-content">
      {exist ? (
        <div className="view-container">
          <div className="card">
            <div className="img">
              <img
                src={`${process.env.REACT_APP_SERVER}/${eventInfo.image}`}
                alt="Event_image"
              />
            </div>
            <div className="info">
              <h1>{eventInfo.title}</h1>
              <p>{eventInfo.subtitle}</p>
              <p className="date">
                Date: {timestampParser(eventInfo.date)} à {eventInfo.heure}
              </p>

              <p className="description" />
              <h3>
                <a href="#" onClick={() => window.open(`${eventInfo.link}`)}>
                  Participer
                </a>
              </h3>
              <input
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_CLIENT}${location.pathname}`
                  );
                  setTimeout(() => {
                    document.querySelector(".to-copy").value =
                      "Copier le lien et partager";
                  }, 3000);

                  document.querySelector(".to-copy").value = "Copié";
                }}
                className="to-copy"
                defaultValue="Copier le lien et partager"
              />
            </div>
          </div>
        </div>
      ) : (
        <Nothing
          name={"événements"}
          locate={"evenements"}
          texte={"Cet événemant n'exite pas"}
        />
      )}
    </div>
  );
};

export default EventView;
