import React, { useEffect } from "react";
import Header from "../components/Header";
import Portfolio from "../components/Portfolio";
import PortfolioData from "../data/PortfolioData";
const Realisation = () => {
  useEffect(() => {
    document.title = "Nos réalisations";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <Header
        title={"Les Réalisations"}
        description={"Sites web, applications mobiles."}
      />
      <div className="wrapper">
        {PortfolioData.map((portfolio) => {
          return (
            <Portfolio
              title={portfolio.title}
              image={portfolio.image}
              description={portfolio.description}
              link={portfolio.link}
              subtitle={portfolio.subtitle}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Realisation;
