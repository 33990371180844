import React, { useEffect } from "react";
import Header from "../components/Header";
import Images from "../constantes/images";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";

const About = () => {
  useEffect(() => {
    document.title = "À propos";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <Header
        title={"Tout savoir sur nous."}
        description={
          "Notre passion : aider les particuliers à développer leur activité grâce au digital."
        }
      />
      <div className="about-section">
        <Fade top>
          <img src={Images.about} alt="about_image" />
        </Fade>
        <p>
          <Fade left>
            <span>
              SC Digit@l est une startup spécialisée dans la Formation
              (présentielle, en ligne ou à domicile), la consultation et la
              conception de solution informatique pour les particuliers et les
              entreprises. Elle regroupe des experts spécialisés des grandes
              universités du monde plus précisément des États-Unis. Des
              spécialistes en IT (du géant Google), en développement (web et
              mobile) et bien d'autres.
            </span>
          </Fade>

          <Fade left>
            <span>Bonne nouvelle !</span>
          </Fade>
          <Fade right>
            <span>
              Vous avez la possibilité d'intégrer l'équipe SC Digital afin de
              bénéficier des avantages d'être membre.
            </span>
          </Fade>
          <Fade bottom>
            <NavLink to="devenir-membre">
              <input
                type="button"
                className="btn-design"
                value="Devenir membre"
              />
            </NavLink>
            <NavLink to="/avantages">
              <input
                type="button"
                className="btn-design"
                value="Voir les avantages"
              />
            </NavLink>
          </Fade>
        </p>
      </div>
    </div>
  );
};

export default About;
