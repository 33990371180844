/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Images from "../constantes/images";
import NewsLetter from "./NewsLetter";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="flex">
        <div className="part-one">
          <div className="left">
            <h2>SC Digital</h2>
            <h4>Adresse</h4>
            <p>{process.env.REACT_APP_ADDRESS}</p>
            <h4>Téléphones</h4>
            <p>{process.env.REACT_APP_TEL_1}</p>
            <p>{process.env.REACT_APP_TEL_2}</p>
          </div>
          <div className="right">
            <h4>Email</h4>
            <span>{process.env.REACT_APP_MAIL}</span>
            <h4>Réseaux</h4>
            <div className="social-icons">
              <img
                src={Images.facebook}
                alt="facebook"
                onClick={() =>
                  window.open(`https://web.facebook.com/ScDigital225`)
                }
              />
              <img
                src={Images.whatsapp}
                alt="whatsapp"
                onClick={() =>
                  window.open(`https://wa.me/${process.env.REACT_APP_WHATSAPP}`)
                }
              />
            </div>
          </div>
        </div>
        <div className="part-two">
          <h2>Liens</h2>
          <ul className="footer-links">
            <li className="nav-item">
              <NavLink
                to="/a-propos"
                className="nav-link"
                activeClassName="active-link"
              >
                À propos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/services"
                className="nav-link"
                activeClassName="active-link"
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/evenements"
                className="nav-link"
                activeClassName="active-link"
              >
                Évènements
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/emploi"
                className="nav-link"
                activeClassName="active-link"
              >
                Poster pour un emploi
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/stage"
                className="nav-link"
                activeClassName="active-link"
              >
                Poster pour un stage
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/devenir-membre"
                className="nav-link"
                activeClassName="active-link"
              >
                Devenir membre
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/blog"
                className="nav-link"
                activeClassName="active-link"
              >
                Blog
              </NavLink>
            </li>
          </ul>
          <div className="top-separator">
            <h2>S'inscrire au newsletter</h2>
            <NewsLetter />
          </div>
        </div>
      </div>
      <p className="copyright">SC Digital, 2021, TOUS DROITS RÉSERVÉS</p>
    </footer>
  );
};

export default Footer;
