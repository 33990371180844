/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { dateParser, timestampParser } from "../components/Utils";
import Zoom from "react-reveal/Zoom";
import { NavLink } from "react-router-dom";

const EventCard = ({ EventData }) => {
  return (
    <Zoom left>
      <div className="blog-card">
        <div className="meta">
          <div className="photo">
            <img
              src={`${process.env.REACT_APP_SERVER}/${EventData.image}`}
              alt="Event_image"
            />
          </div>
          <ul className="details">
            <li className="author">
              <a href="#">Admin</a>
            </li>
            <li className="date">{EventData.date}</li>
            <li className="tags">
              <ul>
                <li>
                  <a href="#">{EventData.tag}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="description">
          <h1>{EventData.title}</h1>
          <h2>{EventData.subtitle}</h2>
          <p className="date">
            Date: {timestampParser(EventData.date)} à {EventData.heure}
          </p>
          <p className="date">Publié le: {dateParser(EventData.createdAt)}</p>
          <p className="read-more">
            <NavLink to={`/evenements/${EventData._id}`}>Lire</NavLink>
            <a href="#" onClick={() => window.open(`${EventData.link}`)}>
              Participer
            </a>
          </p>
        </div>
      </div>
    </Zoom>
  );
};

export default EventCard;
