import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import BlogCard from "../components/BlogCard";
import axios from "axios";
import { dateParser, isEmpty } from "../components/Utils";
import Search from "../components/Search";
import Images from "../constantes/images";

const Blog = () => {
  const [ blogData, setBlogData ] = useState([]);
  const [ filterData, setFilterData ] = useState();
  const [ input, setInput ] = useState("");
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    document.title = "Blog";
    window.scrollTo({ top: 0, behavior: "smooth" });

    axios
      .get(`${process.env.REACT_APP_SERVER}/api/blog`)
      .then((res) => {
        setBlogData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const updateInput = async (input) => {
    const filtered = blogData.filter((blog) => {
      const fullDate = dateParser(blog.createdAt);
      return (
        blog.title.toLowerCase().includes(input.toLowerCase()) ||
        fullDate.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setFilterData(filtered);
  };

  return (
    <div className="page-content">
      {" "}
      <Header
        title={"Blog."}
        description={
          "Web, SEO, Machine learning, tout savoir grâce à des articles."
        }
      />
      <div className="search-container">
        <Search
          input={input}
          setKeyword={updateInput}
          placeholder="un article"
        />
      </div>
      {loading ? (
        <div className="loading">
          <img src={Images.load} alt="loading" />
        </div>
      ) : (
        <div className="display-grid">
          {filterData ? (
            filterData.map((data) => {
              return <BlogCard BlogData={data} key={data._id} />;
            })
          ) : !isEmpty(blogData) ? (
            blogData.map((data) => {
              return <BlogCard BlogData={data} key={data._id} />;
            })
          ) : (
            <p className="nothing">aucun article</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Blog;
