/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Portfolio = ({ title, image, description, link, subtitle }) => {
  return (
    <div className="panel portoflio">
      <img src={image} alt="image portfolion" />
      <span className="tag">{subtitle}</span>
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={link} target="_blank" className="btn-design">
        Visiter
      </a>
    </div>
  );
};

export default Portfolio;
