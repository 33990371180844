/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Zoom from "react-reveal/Zoom";
import { NavLink } from "react-router-dom";
import { timestampParser } from "./Utils";

const FormationCard = ({ FormationData }) => {
  return (
    <Zoom left>
      <span className="card">
        <div className="img">
          <small>{FormationData.prix} Fcfa</small>
          <img
            src={`${process.env.REACT_APP_SERVER}/${FormationData.image}`}
            className="card__image"
            alt="formation_image"
          />
        </div>

        <div className="card__header">
          <div className="card__header-text">
            <h3 className="card__title">{FormationData.title}</h3>
            <span className="card__status">
              Formation pour le: {timestampParser(FormationData.date)} à{" "}
              {FormationData.heure}
            </span>
            <p className="read-more">
              <NavLink to={`/formations/${FormationData._id}`}>Lire</NavLink>

              <a href={`${FormationData.link}`} target="_blank">
                S'inscrire
              </a>
            </p>
          </div>
        </div>
      </span>
    </Zoom>
  );
};

export default FormationCard;
