/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { dateParser } from "../components/Utils";
import Zoom from "react-reveal/Zoom";
import { NavLink } from "react-router-dom";

const BlogCard = ({ BlogData }) => {
  return (
    <Zoom left>
      <div className="blog-card">
        <div className="meta">
          <div className="photo">
            <img
              src={`${process.env.REACT_APP_SERVER}/${BlogData.image}`}
              alt="Event_image"
            />
          </div>
          <ul className="details">
            <li className="author">
              <a href="#">Admin</a>
            </li>
            <li className="date">{dateParser(BlogData.createdAt)}</li>
            <li className="tags">
              <ul>
                <li>
                  <a href="#">{BlogData.tag}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="description">
          <h1>{BlogData.title}</h1>

          <p className="date">Publié le: {dateParser(BlogData.createdAt)}</p>
          <p className="read-more">
            <NavLink to={`/blog/${BlogData._id}`}>Lire</NavLink>
          </p>
        </div>
      </div>
    </Zoom>
  );
};

export default BlogCard;
