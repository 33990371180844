export const dateParser = (createdAt) => {
  let options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  let timestamp = Date.parse(createdAt);
  let date = new Date(timestamp).toLocaleDateString("fr-CI", options);
  return date.toString();
};

export const timestampParser = (num) => {
  let options = {
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  let date = new Date(num).toLocaleDateString("fr-CI", options);
  return date.toString();
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};
