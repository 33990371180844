/* eslint-disable no-unreachable */
import React, { useState } from "react";
import axios from "axios";
import Fade from "react-reveal/Fade";

const NewsLetter = () => {
  const [ email, setEmail ] = useState("");
  const [ send, setSend ] = useState(false);
  const [ error, setError ] = useState(false);

  const handleEmail = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/email`, { email })
      .then((res) => {
        if (res.data.errors) {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
          document.querySelector(".notsend").innerHTML =
            res.data.errors.emailError || res.data.errors.emailExist;
          setTimeout(() => {
            document.querySelector(".inner").innerHTML = "S'inscrire";
          }, 3000);
        } else {
          setError(false);
          setSend(true);
          setTimeout(() => {
            setSend(false);
          }, 4000);
          setEmail("");
        }
      })
      .catch((err) => console.log("email not send" + err));
  };

  return (
    <Fade top>
      <form className="newsletter" onSubmit={handleEmail}>
        <input
          placeholder="Address E-mail"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {!error ? (
          <button type="submit" className={send ? "sended" : "inner"}>
            {send ? "Inscription a été effectué avec succès" : "S'inscrire"}
          </button>
        ) : (
          <button type="submit" className="notsend" />
        )}
      </form>
    </Fade>
  );
};

export default NewsLetter;
