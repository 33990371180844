import React from "react";
import ReactDOM from "react-dom";
import "./styles/theme.css";
import "./styles/Nav.css";
import "./styles/Home.css";
import "./styles/Header.css";
import "./styles/Footer.css";
import "./styles/Event.css";
import "./styles/NewsLetter.css";
import "./styles/About.css";
import "./styles/Services.css";
import "./styles/Contact.css";
import "./styles/Member.css";
import "./styles/View.css";
import "./styles/Search.css";
import "./styles/Avantages.css";
import "./styles/Formation.css";
import "./styles/Popup.css";
import "./styles/Modal.css";
import "./styles/Portfolio.css";
import "./styles/SendBox.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
