import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import axios from "axios";
import SendBox from "../components/SendBox";

const Member = () => {
  const [send, setSend] = useState(false);
  let message = "Je souhaite rejoindre l'équipe de SC Digital";

  useEffect(() => {
    document.title = "Devenir membre";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleMessage = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_SERVER}/api/message/member`, {
        data,
        message
      })
      .then((res) => console.log("message send"))
      .catch((err) => console.log("message not send"));

    setSend(true);

    setTimeout(() => {
      setSend(false);
    }, 4000);

    setData({
      firstname: "",
      lastname: "",
      phone: "",
      email: ""
    });
  };

  return (
    <div className="page-content">
      {" "}
      <Header
        title={"Devnir membre."}
        description={
          "Bénéficiez de plein d'avantage en étant membre de SC Digital."
        }
      />
      <section className="contact-section">
        <div className="contact-container">
          <div className="contact-form">
            <h2>Envoyez votre requête</h2>
            <form
              method="post"
              name=""
              className="formBox"
              onSubmit={handleMessage}
            >
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  value={data.firstname}
                  required
                />
                <span>Prénom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  value={data.lastname}
                  required
                />
                <span>Nom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                />
                <span>Email *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  value={data.phone}
                  required
                />
                <span>Numéro de téléphone *</span>
              </div>

              <div className="inputBox w100">
                {!send ? (
                  <input type="submit" name="send" value="Envoyer la requête" />
                ) : (
                  <SendBox
                    text="Requête de membre envoyée"
                    onClick={() => setSend(false)}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Member;
