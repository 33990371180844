import React, { useEffect } from "react";

import Header from "../components/Header";

import Images from "../constantes/images";
import Fade from "react-reveal/Fade";

const Services = () => {
  useEffect(() => {
    document.title = "Services";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <Header
        title={"Nos services."}
        description={
          "Formation, Prestation de services, Coaching individuel et club informatique, tout savoir."
        }
      />
      <div className="services-section">
        <div className="wrapper">
          <Fade left>
            <div className="panel">
              <img src={Images.bureau} alt="img" />
              <h3>Formation</h3>
              <p>
                <span>→</span> Chaque cours comprend au moins une heure de
                pratique avec le ou les formateurs. Vous aurez des activités à
                réaliser vous-même.
                <br />
                <span>→</span>Tout au long du cours vous serez en interaction
                avec les autres. Cela vous permet d'améliorer votre compétence
                en matière de collaboration.
                <br />
                <span>→</span>A la fin de chaque formation nous vous mettons à
                l'epreuve avec des exercices pratiques.
              </p>
              <div className="slide">
                <h4>Éléments inclus</h4>
                <ul>
                  <li>Cours pratique</li>
                  <li>Séance de travail</li>
                  <li>Evaluation </li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="panel">
              <img src={Images.bureau2} alt="img" />
              <h3>Prestation de services</h3>
              <p>
                <span>→</span> Avec notre équipe de programmeur informatique
                expérimenté. Nous développons votre site internet
                (e-commerce,site vitrine, blog etc...) et application mobile.
                <br />
                <span>→</span>Nos spécialistes en marketing des réseaux sociaux
                vous permettent d'accroite votre chiffre d'affaire en vendant
                plus grâce aux technique marketing 3.0.
                <br />
                <span>→</span>Aujourd'hui avec internet vous avez la possibilité
                de toucher le maximum de personnes partout. La communication
                n'est pas à négliger sinon votre entreprise meurt.
              </p>
              <div className="slide">
                <h4>Éléments inclus</h4>
                <ul>
                  <li>Développement</li>
                  <li>Marketing</li>
                  <li>Communication digitale</li>
                </ul>
              </div>
            </div>
          </Fade>

          <Fade left>
            <div className="panel">
              <img src={Images.bureau3} alt="img" />
              <h3>Coaching individuel</h3>
              <p>
                <span>→</span>On vous personnalise un programme de formation
                selon votre disponibilité que ce soit à domicile ou dans nos
                locaux.
                <br />
                <span>→</span>Lorsque vous souscrivez pour un coaching
                individuel, nous faisons des séances de formation pratique.
                <br />
                <span>→</span>Après, votre apprentissage nous vous mettons sur
                des projets réels d'entreprises.
              </p>
              <div className="slide">
                <h4>Éléments inclus</h4>
                <ul>
                  <li>Formation</li>
                  <li>Cas pratique</li>
                  <li>Mise en relation</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="panel">
              <img src={Images.pedagogie} alt="img" />
              <h3>Club informatique</h3>
              <p>
                <span>→</span>Une fois par semaine vous aurez accès au club
                informatique afin de pouvoir apprendre de nouvelles choses dans
                le domaine des TIC.
                <br />
                <span>→</span>Nous avons tous des histoires uniques sur terre.
                Lorsque nous les partageons aux autres. Nous leur apportons des
                conseils qui vont leur permettre d'éviter les mêmes erreurs que
                nous.
                <br />
                <span>→</span> Chaque Etudiant apprend à partager son savoir aux
                autres afin de pouvoir developper son talent de formateur 3.0
              </p>
              <div className="slide">
                <h4>Éléments inclus</h4>
                <ul>
                  <li>Astuces informatiques </li>
                  <li>Partage d'expérience </li>
                  <li>peer-to-peer</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade left>
            <div className="panel">
              <img src={Images.zoom} alt="img" />
              <h3>Cours en ligne</h3>
              <p>
                <span>→</span>Chaque cours se présente sous la forme d'une
                séance vidéo au cours de laquelle le formateur 3.0 parle d'un
                sujet en rapport avec le digital. Il peut s'agir par exemple de
                comment vendre efficacement sur internet.
                <br />
                <span>→</span>À la fin de chaque cours, nous vous donnons une
                liste de ressources en ligne qui vous permettront d'approfondir
                les notions abordées.
              </p>
              <div className="slide">
                <h4>Éléments inclus</h4>
                <ul>
                  <li>Vidéo de haute qualité </li>
                  <li>Liens vers des ressources utiles </li>
                  <li>Astuces et conseils</li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Services;
