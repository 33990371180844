import React, { useEffect } from "react";

const Nothing = ({ name, locate, texte }) => {
  useEffect(
    () => {
      document.title = texte;
    },
    [ texte ]
  );

  return (
    <div className="page-content">
      <div className="notfound-container">
        <p className="error">{texte}</p>

        <input
          type="button"
          value={`Aller voir d'autres ${name}`}
          onClick={() =>
            (window.location = `${process.env.REACT_APP_CLIENT}/${locate}`)}
        />
      </div>
    </div>
  );
};

export default Nothing;
