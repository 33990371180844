import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import axios from "axios";
import SendBox from "../components/SendBox";

const Stage = () => {
  const [send, setSend] = useState(false);

  useEffect(() => {
    document.title = "Postuler pour un stage";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",
    function: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleMessage = async (e) => {
    e.preventDefault();
    const StageData = new FormData();

    StageData.append("firstname", data.firstname);
    StageData.append("lastname", data.lastname);
    StageData.append("phone", data.phone);
    StageData.append("email", data.email);
    StageData.append("message", data.message);
    StageData.append("function", data.function);

    await axios
      .post(`${process.env.REACT_APP_SERVER}/api/message/stage`, StageData)
      .then((res) => {
        console.log("Envoyé");
      })
      .catch((err) => console.log("Echec de l'envoi"));
    setSend(true);

    setTimeout(() => {
      setSend(false);
    }, 4000);

    setData({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
      function: ""
    });
  };

  return (
    <div className="page-content">
      {" "}
      <Header title={"Postuler pour un stage."} />
      <section className="contact-section">
        <div className="contact-container">
          <div className="contact-form">
            <h2>Envoyez votre requête</h2>
            <form
              method="post"
              name=""
              className="formBox"
              onSubmit={handleMessage}
            >
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  value={data.firstname}
                  required
                />
                <span>Prénom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  value={data.lastname}
                  required
                />
                <span>Nom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                />
                <span>Email *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  value={data.phone}
                  required
                />
                <span>Numéro de téléphone *</span>
              </div>
              <div className="inputBox w100 to-clear">
                <input
                  type="text"
                  name="function"
                  onChange={handleChange}
                  value={data.function}
                  required
                />
                <span>Fonction *</span>
              </div>
              <div className="inputBox w100 to-clear">
                <textarea
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                  required
                />
                <span>Un message *</span>
              </div>

              <div className="inputBox w100">
                {!send ? (
                  <input type="submit" name="send" value="Envoyer la requête" />
                ) : (
                  <SendBox
                    text="Requête de stage envoyée"
                    onClick={() => setSend(false)}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stage;
