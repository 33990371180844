import React from "react";
import Images from "../constantes/images";

const ToTop = () => {
  return (
    <span>
      <img src={Images.down} alt="up" className="to-top" />
    </span>
  );
};

export default ToTop;
