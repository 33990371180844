import React from "react";

const Search = ({ keyword, setKeyword, placeholder, isFull }) => {
  return (
    <>
      <input
        key="random1"
        className="search-input"
        value={keyword}
        placeholder={`Rechercher ${placeholder}... ${
          isFull ? "(Nom, Date, Heure)" : ""
        }`}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </>
  );
};

export default Search;
