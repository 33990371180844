import Images from "../constantes/images";

const PortfolioData = [
  {
    _id: 0,
    title: "Live mobile film",
    subtitle: "site web",
    description:
      "LIVE MOBILE FILM est un concours de film (court métrage de 3mn) réalisé par smartphone. Chaque année, les jeunes passionnés de cinéma et de digital ainsi que les cinéphiles et les professionnels du 7è art se réunissent pour célébrer la créativité autour de thématiques sociales à travers le digital. Né en 2018, LIVE MOBILE FILM est une initiative pour les jeunes et par les jeunes. Il est porté par la plateforme",
    image: Images.img1,
    link: "https://livemobilefilm.ci/"
  },
  {
    _id: 1,
    title: "Autobus",
    subtitle: "site web",
    description:
      "AUTOBUS sarl est une entreprise de location de voiture de toutes catégories basée à Abidjan. Nous disposons d'une large gamme de véhicule comme suit: Car(39, 53, 57 et 60 places climatisés et no climatisés) Minicar(9, 11, 14 et 21) Mercedes(Class L, C, S), Huyndai, Toyota. La location se fait avec des chauffeurs professionnels.",
    image: Images.img2,
    link: "https://autobus-ci.com/"
  },
  {
    _id: 2,
    title: "Magic parc",
    subtitle: "site web",
    description:
      "MAGIC PARC est une structure engagée dans le divertissement et le loisir des petits et des grands à travers nos arbres de noël, fun zone, team building, visite découverte et aussi nos sorties scolaires  ",
    image: Images.img3,
    link: "https://magic-parc.com/"
  },
  {
    _id: 3,
    title: "ESTK Industrie",
    subtitle: "site web",
    description:
      "ESTK Industrie est une entreprise industrielle intervenant dans la CHAUDRONNERIE, SOUDURE et CONCEPTION ET FABRICATION MECANIQUE.",
    image: Images.img4,
    link: "https://estkindustrie.com/"
  },
  {
    _id: 4,
    title: "NewNumCI",
    subtitle: "application mobile",
    description:
      "NewNumCI est une application mobile de migration de 8 chiffre à 10 chiffre.",
    image: Images.img5,
    link: "#"
  }
];

export default PortfolioData;
