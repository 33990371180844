/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { dateParser, timestampParser } from "../components/Utils";
import Nothing from "../components/Nothing";

const FormationView = (props) => {
  const location = useLocation();
  const [formationInfo, setFormationInfo] = useState({});
  const [exist, setExist] = useState(true);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER}/api/formation/${props.match.params.id}`
      )
      .then((res) => {
        setFormationInfo(res.data);
        document.title = formationInfo.title;
        document.querySelector(".description").innerHTML =
          formationInfo.description;
      })
      .catch((err) => setExist(false));
  }, [formationInfo.description, formationInfo.title, props]);

  return (
    <div className="page-content">
      {exist ? (
        <div className="view-container">
          <div className="card">
            <div className="img">
              <img
                src={`${process.env.REACT_APP_SERVER}/${formationInfo.image}`}
                alt="Event_image"
              />
            </div>
            <div className="info">
              <h1>{formationInfo.title}</h1>

              <p className="date">
                Formation prévue pour le: {timestampParser(formationInfo.date)}{" "}
                à {formationInfo.heure}
              </p>

              <p className="description" />
              <p className="date">
                Publié le: {dateParser(formationInfo.createdAt)}
              </p>
              <h3 className="date">
                Coût de la formation : {formationInfo.prix} Fcfa
              </h3>

              <h3>
                <a href={`${formationInfo.link}`} target="_blank">
                  S'inscrire
                </a>
              </h3>
              <input
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_CLIENT}${location.pathname}`
                  );
                  setTimeout(() => {
                    document.querySelector(".to-copy").value =
                      "Copier le lien et partager";
                  }, 3000);

                  document.querySelector(".to-copy").value = "Copié";
                }}
                className="to-copy"
                defaultValue="Copier le lien et partager"
              />
            </div>
          </div>
        </div>
      ) : (
        <Nothing
          name={"formations"}
          locate={"formations"}
          texte={"Cette formation n'exite pas"}
        />
      )}
    </div>
  );
};

export default FormationView;
