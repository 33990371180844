import logo from "../assets/icons/logo.png";
import menu from "../assets/icons/menu.png";
import close from "../assets/icons/close.png";
import facebook from "../assets/icons/facebook.png";
import whatsapp from "../assets/icons/whatsapp.png";
import whatsapp_2 from "../assets/icons/whatsapp-2.png";
import fax from "../assets/icons/fax.png";
import building from "../assets/icons/building.png";
import envelope from "../assets/icons/envelope.png";
import down from "../assets/icons/down.png";
import plus from "../assets/icons/plus.png";
import info from "../assets/icons/info.png";
import moon from "../assets/icons/moon.png";
import sun from "../assets/icons/sun.png";
import load from "../assets/icons/load.gif";

import group from "../assets/images/group.jpg";
import pedagogie from "../assets/images/pedagogie.jpg";
import bureau from "../assets/images/bureau.jpg";
import bureau2 from "../assets/images/bureau2.jpg";
import bureau3 from "../assets/images/bureau3.jpg";
import team from "../assets/images/team.jpg";
import about from "../assets/images/about.jpg";
import zoom from "../assets/images/zoom.jpg";

import img1 from "../assets/images/img1.png";

import img2 from "../assets/images/img2.png";
import img3 from "../assets/images/img3.png";
import img4 from "../assets/images/img4.png";
import img5 from "../assets/images/img5.jpeg";

const Images = {
  logo,
  close,
  menu,
  facebook,
  whatsapp,
  whatsapp_2,
  fax,
  building,
  envelope,
  down,
  plus,
  info,
  moon,
  sun,
  load,

  group,
  pedagogie,
  bureau,
  bureau2,
  bureau3,
  team,
  about,
  zoom,

  img1,
  img2,
  img3,
  img4,
  img5
};
export default Images;
