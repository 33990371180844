import React, { useEffect } from "react";

const NotFound = (props) => {
  useEffect(
    () => {
      document.title = "Rien trouvé";
    },
    [ props ]
  );

  return (
    <div className="page-content">
      <div className="notfound-container">
        <h1 className="error">404</h1>
        <p>{`${process.env.REACT_APP_CLIENT}${props.location
          .pathname} n'existe pas`}</p>
        <input
          type="button"
          value="Retour en arrière"
          onClick={() => props.history.goBack()}
        />
      </div>
    </div>
  );
};

export default NotFound;
