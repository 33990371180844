import React, { useState, useEffect } from "react";
import Images from "../constantes/images";
import { NavLink } from "react-router-dom";
import Darkmode from "../components/Darkmode";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
  }, []);

  return (
    <nav className={shadow ? "navbar shadow" : "navbar "}>
      <NavLink to="/" className="nav-logo" onClick={() => setOpen(false)}>
        <img src={Images.logo} alt="logo" />
      </NavLink>

      <ul className={open ? "nav-links open" : "nav-links"}>
        <li className="nav-item">
          <NavLink
            exact={true}
            to="/"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Accueil
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/a-propos"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            À propos
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/services"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Services
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/realisations"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Nos réalisations
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/evenements"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Évènements
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/formations"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Formations
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/contactez-nous"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Contactez-nous
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/blog"
            className="nav-link"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Blog
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/devenir-membre"
            className="btn-design"
            onClick={() => setOpen(false)}
            activeClassName="active-link"
          >
            Devenir membre
          </NavLink>
        </li>
        <Darkmode />
      </ul>
      <div onClick={() => setOpen(!open)} className="nav-icon">
        {open ? (
          <img src={Images.close} alt="close" draggable="false" />
        ) : (
          <img src={Images.menu} alt="menu" draggable="false" />
        )}
      </div>
    </nav>
  );
};
export default Nav;
