import React from "react";

const SendBox = ({ text, onClick }) => {
  return (
    <div className="sendbox-container">
      <div className="msg-box">
        <p>{text}</p>
        <input type="button" value="Ok" onClick={onClick} />
      </div>
    </div>
  );
};

export default SendBox;
