import React, { useEffect } from "react";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
const Avantage = () => {
  useEffect(() => {
    document.title = "Les avantages";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <Header
        title={"Les avantages"}
        description={
          "Il est possible de réjoindre l'équipe afin de bénéficier de plusieurs avantages."
        }
      />
      <div className="list-container">
        <ol className="gradient-list">
          <li>25% de réduction sur toutes les formations.</li>
          <li>
            Accès illimité au club informatique (partage de connaissances
            informatiques 100% pratique).
          </li>
          <li>Possibilité de devenir formateur à SC DIGIT@L. </li>
          <li>
            Participation gratuite aux différentes conférences organisées par SC
            DIGIT@L et ses partenaires.
          </li>
          <li>
            Consultation gratuite de vos projets avec nos spécialistes en
            rédaction de TDR.
          </li>
          <li>Construction de votre image et profil Facebook professionnel.</li>
          <li>Un accompagnement au lancement de votre propre formation.</li>
          <li>OBTENEZ un tee-shirt de SC DIGIT@L.</li>
          <li>Bénéficiez d'une carte de Membre.</li>
          <li>Gagnez 10% sur toutes vos recommandations.</li>
        </ol>
        <NavLink to="/devenir-membre" className="avantage-btn">
          <input type="button" value="Dévénir member" className="btn-design" />
        </NavLink>
      </div>
    </div>
  );
};

export default Avantage;
