/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Images from "../constantes/images";
import axios from "axios";
import SendBox from "../components/SendBox";

const Contact = () => {
  useEffect(() => {
    document.title = "Nous contacter";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: ""
  });

  const [send, setSend] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleMessage = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_SERVER}/api/message/contact`, { data })
      .then((res) => {
        console.log("message sent");
      })
      .catch((err) => console.log("message not sent"));
    setSend(true);

    setTimeout(() => {
      setSend(false);
    }, 4000);

    setData({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: ""
    });
  };

  return (
    <div className="page-content">
      <Header
        title={"Nous contacter."}
        description={
          "Vous avez des questions, des remarques ? Merci de nous envoyer votre message. Nous sommes là pour vous aider."
        }
      />

      <section className="contact-section">
        <div className="contact-container">
          <div className="contact-info">
            <div>
              <h2>Contactez-nous</h2>
              <ul className="info">
                <li>
                  <span>
                    <img src={Images.building} alt="addresse" />
                  </span>
                  <span>{process.env.REACT_APP_ADDRESS}</span>
                </li>
                <li>
                  <span>
                    <img src={Images.envelope} alt="mail" />
                  </span>
                  <span>{process.env.REACT_APP_MAIL}</span>
                </li>
                <li>
                  <span>
                    <img src={Images.fax} alt="téléphone" />
                  </span>
                  <span>
                    {process.env.REACT_APP_TEL_1}/ {process.env.REACT_APP_TEL_2}
                  </span>
                </li>
              </ul>
            </div>
            <ul className="sci">
              <li>
                <a
                  onClick={() =>
                    window.open(`https://web.facebook.com/ScDigital225`)
                  }
                >
                  <img src={Images.facebook} alt="" />
                </a>
              </li>

              <li>
                <a
                  onClick={() =>
                    window.open(
                      `https://wa.me/${process.env.REACT_APP_WHATSAPP}`
                    )
                  }
                >
                  <img src={Images.whatsapp} alt="" />
                </a>
              </li>
            </ul>
          </div>

          <div className="contact-form">
            <h2>Envoyez un message</h2>
            <form className="formBox" onSubmit={handleMessage}>
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="firstname"
                  value={data.firstname}
                  onChange={handleChange}
                  required
                />
                <span>Prénom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="text"
                  name="lastname"
                  value={data.lastname}
                  onChange={handleChange}
                  required
                />
                <span>Nom *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  required
                />
                <span>Email *</span>
              </div>
              <div className="inputBox w50 to-clear">
                <input
                  type="tel"
                  name="phone"
                  value={data.phone}
                  onChange={handleChange}
                  required
                />
                <span>Numéro de téléphone *</span>
              </div>
              <div className="inputBox w100 to-clear">
                <textarea
                  name="message"
                  id=""
                  value={data.message}
                  onChange={handleChange}
                  required
                />
                <span>Votre message *</span>
              </div>
              <div className="inputBox w100">
                {!send ? (
                  <input type="submit" value="Envoyer" />
                ) : (
                  <SendBox
                    text="Message envoyé"
                    onClick={() => setSend(false)}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
